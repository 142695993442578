<!--
 * @Author: xiaziwen
 * @Date: 2021-01-07 09:04:42
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-24 16:11:03
 * @FilePath: \crm-education\src\components\business\progressbar\index.vue
-->
<template>
  <div class="progress-bar d-flex align-center">
    <div
      class="progress-icon-circle px-10"
      :style="{
        width: options.size,
        height: options.size,
        backgroundColor: options.theme,
      }"
    >
      <img class="progress-icon" :src="iconUrl" />
    </div>
    <p class="progress-title fw-bold fs-18 px-10">{{ options.title }}</p>
    <div class="progress-content px-10" v-if="options.minNum">
      <p class="fs-32 fw-bold single-line">{{ options.minNum }}</p>
    </div>
    <div class="progress-content px-10" v-else-if="options.text">
      <p class="fs-24 fw-bold single-line">{{ options.text }}</p>
    </div>
    <div class="progress-content px-10" v-else>
      <p class="fs-32">
        <span class="fw-bold" :style="{ color: options.theme }">{{
          options.num
        }}</span
        >/<span class="fs-18">{{ options.denom }}</span>
      </p>
      <a-progress
        :percent="(options.num / options.denom) * 100"
        :strokeColor="options.theme"
        :show-info="false"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'progress_bar',
  data () {
    return {}
  },
  props: {
    options: {
      type: Object,
      default: () => ({
        theme: '#4071fb',
        size: '64px',
        title: '课程',
        url: 'process-icon-kc',
        denom: 22, // 分母
        num: 20 // 分子
      })
    }
  },
  computed: {
    iconUrl () {
      return require('@/assets/img/' + this.options.url + '.png')
    }
  }
}
</script>

<style lang="scss" scoped>
.progress-bar {
  .progress-icon-circle {
    border-radius: 50%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 20px;
    // background-image: url('../../../assets/img/process-icon-kc.png')
    .progress-icon {
      width: 100%;
      height: 100%;
    }
  }
  .progress-content {
    text-align: center;
    flex: 1;
    height: 69px;
    .single-line{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
}
</style>
